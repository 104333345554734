import { resolveRestUrl } from 'src/utils/urlUtil'

export function jsonGet<T>(url: string) {
  return jsonRequest<T>(url, 'GET', null, undefined)
}

export function jsonPost<T>(url: string, data?: unknown) {
  return jsonRequest<T>(url, 'POST', data, undefined)
}

export function rawPost(
  url: string,
  data?: unknown,
  headers?: HeadersInit,
  abortController?: AbortController,
) {
  return doRequest(url, 'POST', data as BodyInit, headers, abortController)
}

export function rawGet(url: string, headers?: HeadersInit) {
  return doRequest(url, 'GET', undefined, headers)
}

async function jsonRequest<T>(
  url: string,
  method: string,
  data: unknown,
  headers = {},
) {
  const x = await doRequest(
    url,
    method,
    data ? JSON.stringify(data) : undefined,
    {
      ...headers,
      'Content-Type': 'application/json; charset=UTF-8',
    },
  )
  const text = await x.text()
  let value: T
  try {
    value = JSON.parse(text) as T
  } catch (e) {
    value = text as unknown as T
  }
  // only return value if response is ok, otherwise throw error (which might be a ValidationError)
  if (x.ok) {
    return value
  }
  throw value
}

function doRequest(
  url: string,
  method: string,
  data: BodyInit | undefined,
  headers: HeadersInit | undefined,
  abortController?: AbortController,
) {
  const withCredentials = window.location.host.indexOf(':3000') !== -1
  const params: RequestInit = {
    method,
    headers,
    credentials: withCredentials ? 'include' : 'same-origin',
    body: data,
    signal: abortController?.signal,
  }
  return fetch(resolveRestUrl(url), params)
}

export const isErrorResponse = (error: any): boolean => {
  return error.hasOwnProperty('type') && error.hasOwnProperty('message')
}
