import { User } from 'src/flow-types/Trip'

import { useErrorHandler } from 'src/hooks'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AI_FILE_PARSE, USER_TYPE } from 'src/utils/const'
import { getCookie } from 'src/utils/cookie'

import { jsonGet, rawPost } from 'src/utils/httpUtil'
import { AVAILABLE_PLUGIN } from 'src/pages/settings/tabs/linkedApps/availablePlugins'

type UserContextType = {
  user: ERUser
  isProd: boolean
  refreshUserContext: () => void
  showGuestModal: boolean
  setShowGuestModal: (arg1: ((arg1: boolean) => boolean) | boolean) => void
  getLocalUserSettings: (name: string) => any | undefined
  setLocalUserSettings: (name: string, value: any) => void
  updateFileParse: (fileParseStatus: AI_FILE_PARSE) => Promise<null>
  isGuest: boolean
  isTrial: boolean
  isBasic: boolean
}

export type ERUser = {
  canExport: boolean
  companyId: number | null
  email: string
  newUser: boolean
  noLoginUsersEnabled: boolean
  notifications: Array<string>
  packageInfo: {
    canAccountBeProlonged: boolean
    canMakePayments: boolean
    expired: boolean
    expires: string
    freeTrips: number
    name: string
    packageType: string
  }
  pluginType: AVAILABLE_PLUGIN | null
  team: {
    approvalWorkflow: boolean
    teamGroupPermission: boolean
  } | null
  user: User
  userRole: string
  isAuthenticated: boolean | null
  aiFileParse: AI_FILE_PARSE
}

const initialState: ERUser = {
  canExport: false,
  companyId: null,
  email: '',
  newUser: false,
  noLoginUsersEnabled: false,
  notifications: [],
  packageInfo: {
    canAccountBeProlonged: false,
    canMakePayments: false,
    expired: false,
    expires: '',
    freeTrips: 0,
    name: '',
    packageType: '',
  },
  pluginType: null,
  team: {
    approvalWorkflow: false,
    teamGroupPermission: false,
  },
  user: {
    firstName: '',
    id: '',
    lastName: '',
    status: '',
  },
  userRole: '',
  aiFileParse: AI_FILE_PARSE.NONE,
  isAuthenticated: false,
}

const UserContext = createContext<UserContextType>({} as UserContextType)

type UserProviderProps = {
  children: ReactNode
}

function UserProvider(props: UserProviderProps): ReactNode {
  const { children } = props
  const handleError = useErrorHandler()
  const [user, setUser] = useState<ERUser>(initialState)
  const [isGuest, setIsGuest] = useState(false)
  const [isTrial, setIsTrial] = useState(false)
  const [isBasic, setIsBasic] = useState(false)
  const [showGuestModal, setShowGuestModal] = useState(false)

  const getLocalUserSettings = (name: string): string | undefined => {
    if (user.user?.id) {
      const settingsFromStorage = localStorage.getItem('userSettings')
      const settings = settingsFromStorage
        ? JSON.parse(settingsFromStorage)
        : null

      return name &&
        settings &&
        settings[user.user.id] &&
        settings[user.user.id][name]
        ? settings[user.user.id][name]
        : null
    }
  }

  // LOCALSTORE MIGRATION FOR CURRENT USERS
  // SHOULD BE REMOVED @ 01.03.22
  useEffect(() => {
    const migrateLocalStorage = () => {
      const oldVisibility = localStorage.getItem(
        'EinfachReisekostenFilterSettings',
      )
      const oldSort = localStorage.getItem('EinfachReisekostenListingSort')

      if (oldVisibility) {
        setLocalUserSettings('filterVisibility', JSON.parse(oldVisibility))
        localStorage.removeItem('EinfachReisekostenFilterSettings')
      }

      if (oldSort) {
        setLocalUserSettings('listingSort', oldSort)
        localStorage.removeItem('EinfachReisekostenListingSort')
      }
    }

    setIsGuest(USER_TYPE.GUEST === user.userRole)
    setIsTrial(USER_TYPE.TRIAL_USER === user.userRole)
    setIsBasic(USER_TYPE.BASIC_USER === user.userRole)

    if (user.user?.id) {
      migrateLocalStorage()
    }
    // eslint-disable-next-line
  }, [user])

  const setLocalUserSettings = useCallback(
    (name: string, value: any) => {
      if (user.user?.id) {
        const settingsFromStorage = localStorage.getItem('userSettings')
        const settings = settingsFromStorage
          ? JSON.parse(settingsFromStorage)
          : {}

        localStorage.setItem(
          'userSettings',
          JSON.stringify({
            ...settings,
            [user.user.id]: {
              ...(settings[user.user.id] ?? {}),
              [name]: value,
            },
          }),
        )
      }
    },
    [user.user.id],
  )

  const refreshUserContext = useCallback(() => {
    jsonGet<ERUser>('user/public/user-context')
      .then((response) => {
        if (!response) {
          // 200 but no response
          if (!window.location.pathname.startsWith('/public')) {
            window.location.href =
              '/public/login?redirect=' +
              encodeURIComponent(
                window.location.pathname + window.location.search,
              )
          }
        } else {
          setUser({
            ...response,
            isAuthenticated: true,
          })
          if (
            !window.location.pathname.startsWith('/public') &&
            response.userRole === 'GUEST'
          ) {
            const cookieId = getCookie('guest')
            const path = '/trip/' + (cookieId || '')
            if (window.location.pathname !== path) {
              window.location.pathname = path
            }
          }
        }
      })
      .catch((error) => {
        setUser(initialState)
        handleError(error)
      })
  }, [handleError])

  useEffect(refreshUserContext, [refreshUserContext])

  const updateFileParse = (fileParseStatus: AI_FILE_PARSE): Promise<null> => {
    return new Promise(function (resolve, reject) {
      rawPost('user/settings/ai_file_parse/update/' + fileParseStatus)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Error updating ai_file_parse`)
          } else {
            refreshUserContext()
            resolve(null)
          }
        })
        .catch((e) => {
          handleError(e)
          reject(null)
        })
    })
  }

  const isProd = window.location.host === 'app.einfach-reisekosten.de'

  return (
    <UserContext.Provider
      value={{
        user,
        isProd,
        refreshUserContext,
        showGuestModal,
        setShowGuestModal,
        getLocalUserSettings,
        setLocalUserSettings,
        isGuest,
        isTrial,
        isBasic,
        updateFileParse,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUserContext() {
  const ctx = useContext(UserContext)

  if (!ctx) {
    throw new Error('useUserContext must be used within UserProvider')
  }

  return ctx
}

export { useUserContext, UserProvider }
