import { createContext, ReactNode, useContext } from 'react'
import { useUserContext } from 'src/context/user'

type SnipperContextType = {
  openCoviewSupport: () => void
  injectCoview: () => void
}

const SnippetContext = createContext<SnipperContextType>(
  {} as SnipperContextType
)

type SnippetProviderProps = {
  children: ReactNode
}

function SnippetProvider(props: SnippetProviderProps): ReactNode {
  const { children } = props
  const { isProd, user } = useUserContext()
  const myWindow: any = window
  const openCoviewSupport = () => {
    if (!myWindow['coview']) {
      console.log('coview is not defined')
    } else {
      myWindow['coview']('openWidget')
    }
  }

  const injectCoview = () => {
    const scriptUrl = 'https://cdn.coview.com/coview.js'
    if (
      !isProd ||
      document.querySelectorAll('[src="' + scriptUrl + '"]').length
    ) {
      return
    }

    myWindow['coview'] =
      myWindow['coview'] ||
      function () {
        ;(myWindow['coview'].a = myWindow['coview'].a || []).push(arguments)
      }

    let script = document.createElement('script')
    script.src = scriptUrl
    script.async = true
    if (document.head) {
      document.head.append(script)
    }
    myWindow.addEventListener('error', function (err: ErrorEvent) {
      myWindow['coview']('error', err)
    })
    myWindow.addEventListener('message', function (msg: MessageEvent) {
      myWindow['coview']('message', msg)
    })
    myWindow['coview']('set', {
      projectKey: 'TDEXx1WLV-8',
      publicPage: false,
      chat: { showButton: false },
      hostUrl: 'https://app.coview.com',
      user: {
        id: user?.user.id,
        email: user?.email,
      },
    })
  }

  return (
    <SnippetContext.Provider
      value={{
        openCoviewSupport,
        injectCoview,
      }}
    >
      {children}
    </SnippetContext.Provider>
  )
}

function useSnippetContext() {
  const ctx = useContext(SnippetContext)

  if (!ctx) {
    throw new Error('useSnippetContext must be used within SnippetProvider')
  }

  return ctx
}

export { useSnippetContext, SnippetProvider }
