import { lazy, Suspense } from 'react'
import { useUserContext } from 'src/context/user'
import AppProviders from 'src/app/Providers'
import * as serviceWorker from 'src/serviceWorker'

import 'src/assets/css/bootstrap-reboot.min.css'
/*
For test reasons currently disabled and moved to index.html
import 'assets/css/fonts/lato/index.css'
import 'assets/css/fonts/material_icons/index.css'
*/
import './index.css'

import { createRoot } from 'react-dom/client'

const UnauthenticatedApp = lazy(() => import('./app/Unauthenticated'))
const AuthenticatedApp = lazy(() => import('./app/Authenticated'))
const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Failed to find root element')
}
const root = createRoot(rootElement)

const App = () => {
  const {
    user: { isAuthenticated },
  } = useUserContext()

  return (
    <>
      <Suspense fallback={null}>
        {isAuthenticated && <AuthenticatedApp />}
      </Suspense>
      <Suspense fallback={null}>
        <UnauthenticatedApp />
      </Suspense>
    </>
  )
}

root.render(
  <AppProviders>
    <App />
  </AppProviders>
)

serviceWorker.register()
