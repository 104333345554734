import { User } from 'src/flow-types/Trip'

export enum FileParseStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_USE = 'IN_USE',
}

export type Group = {
  id: string
  title: string
  totalTrips: number
  totalCosts: number
  teamGroup: boolean
}

export type FlatFilter = {
  id: string | null
  value: string | null
  tripCount: number
  tripCost: number | null
}

export type FlatFilters = {
  year: FlatFilter
  month: FlatFilter
  user: FlatFilter
  project: FlatFilter
  costCenter: FlatFilter
  companyPaidAll: FlatFilter
  approvalStatus: FlatFilter
  exportStatus: FlatFilter
  group: ListingGroup
}

export type UberFlatFilters = {
  year: string | null
  month: string | null
  user: string | null
  project: string | null
  costCenter: string | null
  companyPaidAll: string | null
  approvalStatus: string | null
  exportStatus: string | null
  group: string | null
}

export type FilterProp<T> = {
  id?: T
  selectItems?: Array<{
    value: string
    description: string
    totalTrips?: string
    totalCosts?: string
  }>
  selected?: {
    value: string
    description: string
  }
  firstName?: string
  lastName?: string
  tripCount?: string
  tripCost?: string
  value?: string
  placeholder?: string
  messageWhenEmpty?: string
  withSummary?: boolean
}

export type FiltersProps = {
  year?: FilterProp<string>
  month?: FilterProp<string>
  group?: FilterProp<string>
  user?: FilterProp<string>
  project?: FilterProp<string>
  costCenter?: FilterProp<string>
  companyPaidAll?: FilterProp<boolean>
  approvalStatus?: FilterProp<string>
  exportStatus?: FilterProp<string>
}

export type ListingGroup = {
  end: string | null
  id: string
  start: string | null
  teamGroup: boolean
  title: string
  totalCosts: number
  totalTrips: number
  user: User
}
export type ListingGroups = Array<ListingGroup>

export type QueryParams = {
  sort: string
  year: string | number
  month?: string
  userid?: string
  project?: string
  costCenter?: string
  groupid?: string
  selecttripid?: string | null
  companyPaidAll?: boolean
  approvalStatus?: string | null
  exportStatus?: string | null
}

export type InputValidation = {
  type: string
  message: string
}

export type InputValidations = Array<InputValidation>

export enum PackageType {
  BASIC = 'BASIC',
  REGULAR = 'REGULAR',
  TEAM = 'TEAM',
  TEAM_USER = 'TEAM_USER',
}
