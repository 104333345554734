import React, { ReactNode, StrictMode } from 'react'

import { ToastProvider } from 'src/context/toast'
import { UserProvider } from 'src/context/user'
import { SnippetProvider } from 'src/context/snippets'

type Props = {
  children: ReactNode
}

export default function AppProviders({ children }: Props): ReactNode {
  return (
    <StrictMode>
      <ToastProvider>
        <UserProvider>
          <SnippetProvider>{children}</SnippetProvider>
        </UserProvider>
      </ToastProvider>
    </StrictMode>
  )
}
