import { useToastContext } from 'src/context/toast'
import { useCallback, useEffect, useState } from 'react'

export function useErrorHandler() {
  const { addToastNotice } = useToastContext()
  const [error, setError] = useState<any>()

  const handleError = useCallback(
    (error: any) => {
      if (error.status === 401) {
        console.log('401 error, waiting for usercontext to redirect to login')
        return
      }

      let message
      if (error.response) {
        try {
          message = JSON.parse(error.response).message
        } catch (e) {
          console.log(e)
        }
      } else if (error.message) {
        message = error.message
      }

      addToastNotice({
        type: 'ERROR',
        message: message || 'Es ist ein Fehler aufgetreten'
      })

      setError(null)
      console.log(error)
    },
    [addToastNotice]
  )

  useEffect(() => {
    if (error) {
      if (
        error.responseType === 'blob' &&
        error.response?.type === 'application/json'
      ) {
        error.response
          .text()
          .then((responseText: any) => {
            handleError({
              status: error.status,
              response: responseText
            })
          })
          .catch(console.log)
      } else {
        handleError(error)
      }
    }
  }, [error, handleError])

  return setError
}
