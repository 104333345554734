import { ReactNode } from 'react'
import { useEffect } from 'react'
import { Toasts, ToastWIthId } from 'src/context/toast'
import IconButton from './IconButton'

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import styles from './ToastNotifications.module.css'

type ToastNotificationsProps = {
  toasts: Toasts
  onRemove: (id: string) => void
}

const MESSAGE_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
}

const MESSAGE_TYPES_DEFAULTS = {
  [MESSAGE_TYPES.SUCCESS]: {
    icon: <CheckIcon />,
    className: styles.SUCCESS,
    timeout: 2000,
  },
  [MESSAGE_TYPES.ERROR]: {
    icon: <ErrorOutlineOutlinedIcon />,
    className: styles.ERROR,
    timeout: 6000,
  },
  [MESSAGE_TYPES.INFO]: {
    icon: <InfoOutlinedIcon />,
    className: styles.INFO,
    timeout: 6000,
  },
  [MESSAGE_TYPES.WARN]: {
    icon: <ReportProblemOutlinedIcon />,
    className: styles.WARN,
    timeout: 6000,
  },
} as const

export default function ToastNotifications(
  props: ToastNotificationsProps
): ReactNode {
  const { toasts, onRemove } = props

  return (
    <>
      {toasts.length > 0 && (
        <div className={styles.container}>
          {toasts.map((toast) => (
            <Toast key={toast.id} toast={toast} onRemove={onRemove} />
          ))}
        </div>
      )}
    </>
  )
}

type ToastProps = {
  onRemove: (id: string) => void
  toast: ToastWIthId
}

const Toast = (prop: ToastProps) => {
  const { toast, onRemove } = prop

  useEffect(() => {
    setTimeout(() => {
      onRemove(toast.id)
    }, toast.timeout ?? MESSAGE_TYPES_DEFAULTS[toast.type].timeout)
  }, [onRemove, toast])

  return (
    <div className={styles.toastContainer}>
      <div
        className={`
        ${styles.icon}
        ${MESSAGE_TYPES_DEFAULTS[toast.type].className}
      `}
      >
        {MESSAGE_TYPES_DEFAULTS[toast.type].icon}
      </div>
      <div className={styles.message}>
        {toast.message}
        {toast.linkText && toast.linkOnClick && (
          <span
            className={styles.spanLink}
            onClick={() => {
              toast.linkOnClick && toast.linkOnClick()
              onRemove(toast.id)
            }}
          >
            {toast.linkText}
          </span>
        )}
      </div>
      <IconButton
        icon={<CloseIcon />}
        onClick={() => {
          onRemove(toast.id)
        }}
      />
    </div>
  )
}
