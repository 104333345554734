export function resolveRestUrl(url) {
  return resolveUrl('v1/' + url)
}

export function resolveUrl(url) {
  let host = window.location.host
  host = host.replace(':3000', ':8080')
  return `${window.location.protocol}//${host}/${url}`
}

export function getQueryParam(name) {
  const query = window.location.search
  return new URLSearchParams(query).get(name)
}

export const deconstructQueryString = (queryString) => {
  const pairs = queryString.slice(1).split('&')

  return pairs.reduce((result, pair) => {
    const [key, value] = pair.split('=')

    result[key] = decodeURI(value)

    return result
  }, {})
}

export const constructQueryString = (queryParams) => {
  const entries = Object.entries(queryParams)

  if (entries.length === 0) return ''

  const pairs = entries.reduce((result, [key, value]) => {
    if (value) {
      result.push(`${key}=${encodeURI(value)}`)
    }

    return result
  }, [])

  return `?${pairs.join('&')}`
}
