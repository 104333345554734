import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import ToastNotifications from 'src/components/base/ToastNotifications'

type ToastContextType = {
  addToastNotice: (toast: Toast) => void
}

const ToastContext = createContext<ToastContextType>({} as ToastContextType)

type ToastProviderProps = {
  children: ReactNode
}

export type Toast = {
  type: string
  message: ReactNode
  timeout?: number
  linkText?: string
  linkOnClick?: () => void
}

export type ToastWIthId = Toast & { id: string }

export type Toasts = Array<ToastWIthId>

function ToastProvider(props: ToastProviderProps): ReactNode {
  const { children } = props
  const [toasts, setToasts] = useState<Toasts>([])

  const addToastNotice = useCallback((newToast: Toast) => {
    setToasts((toasts) => [{ ...newToast, id: uuidv4() }, ...toasts])
  }, [])

  const handleRemove = (removeId: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== removeId))
  }

  return (
    <ToastContext.Provider
      value={{
        addToastNotice,
      }}
    >
      {children}
      <ToastNotifications toasts={toasts} onRemove={handleRemove} />
    </ToastContext.Provider>
  )
}

function useToastContext() {
  const ctx = useContext(ToastContext)

  if (!ctx) {
    throw new Error('useToastContext must be used within ToastProvider')
  }

  return ctx
}

export { useToastContext, ToastProvider }
